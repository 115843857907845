/**
 * App Routes
 */
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
// router service
import routerService from "../../router_service";
import Header from './header.jsx';
import SideMobileViewNavbar from './mobileViewNavbar.jsx'
import SideNavbar from './sideNavbar.jsx';




class DefaultLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showNavbarIs: true,
			isSmallScreen: window.innerWidth <= 600,
		}
	}
	hideSideNavBar = () => {
		this.setState({ showNavbarIs: !this.state.showNavbarIs })
	}

	componentDidMount() {
		this.mediaQuery = window.matchMedia('(max-width: 600px)');
		this.mediaQuery.addListener(this.handleScreenChange);
	  }
	
	  componentWillUnmount() {
		this.mediaQuery.removeListener(this.handleScreenChange);
	  }
	
	  handleScreenChange = (e) => {
		this.setState({ isSmallScreen: e.matches });
	  };
	  
	render() {
		const { showNavbarIs, isSmallScreen } = this.state
		const { match, menu, logout } = this.props;
		return (
			<div className="main-wrapper">
				<Header showNavbarIs={showNavbarIs} menu={menu} logout={logout} />
				<>
					<p onClick={() => this.hideSideNavBar()} className='sideNavbarIcon'><i className="fa-lg fa fa-bars text-white" aria-hidden="true"></i></p>
				</>

				{showNavbarIs ? <div className='sideNav'>
					{isSmallScreen ? <SideMobileViewNavbar menu={menu} showNavbarIs={showNavbarIs} /> : <SideNavbar  menu={menu} showNavbarIs={showNavbarIs} />}
				</div> : null}

				<div style={{
					marginLeft: showNavbarIs && (isSmallScreen ? '56px' : '165px'),
				}} className='mainPageDash'>
					{routerService && routerService.map((route, key) => {
						return <Route key={key} path={`${match.url}/${route.path}`} component={route.component} />
					})}
				</div>
			</div>
		);
	}
}
export default withRouter(DefaultLayout);

// export default withRouter(connect(null)(DefaultLayout));
