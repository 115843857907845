/**
 * Performance Route
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom'; 
import EmployeePerformanceReviewReport from './Report';
import EmployeePerformanceReview from './Review';
import PerformanceDetailSetupRoute from './Review/PerformanceDetailSetupRoute';
import PerformanceTemplate from './Template'; 
import TemplateDetailSetupRoute from './Template/TemplateDetailSetupRoute';
import ReportDetailSetupRoute from './Report/ReportDetailSetupRoute';
import EmployeePerfomanceCycle from './ReviewCycle';
import EmployeePerformanceModule from './PerformanceModule';



const PerformanceRoute = ({ match }) => { 
   return (
   <Switch>
      <Route path={`${match.url}/report/details`} component={ReportDetailSetupRoute} />
      <Route path={`${match.url}/report`} component={EmployeePerformanceReviewReport} />
      {/* <Route path={`${match.url}/review/details`} component={PerformanceDetailSetupRoute} /> */}
      <Route path={`${match.url}/review`} component={EmployeePerformanceModule} />
      <Route path={`${match.url}/cycle`} component={EmployeePerfomanceCycle} />
      {/* <Route path={`${match.url}/template/details`} component={TemplateDetailSetupRoute} />
      
      <Route path={`${match.url}/template`} component={PerformanceTemplate} /> */}
      
   </Switch>
)};
export default PerformanceRoute;
