import React, { Component } from 'react'
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Cell, ReferenceLine } from 'recharts';
import { FcCalendar, FcClock, FcLeave } from "react-icons/fc";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoWarningOutline } from "react-icons/io5";
import { IoIosCheckboxOutline } from "react-icons/io";
import { MdFlightTakeoff } from "react-icons/md";
import { styled } from '@mui/material';
import Tooltipmui, { tooltipClasses } from '@mui/material/Tooltip';
import { GiHummingbird, GiSandsOfTime } from "react-icons/gi";
import { FaAward, FaUmbrellaBeach } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { TbFriendsOff } from "react-icons/tb";
import { Button } from '@mui/material';
import AttendanceStatusList from '../TeamAttendance/AttendanceStatusview';
import { getEmployeeDashboardDetail } from '../../../../MainPage/Main/Dashboard/service';
import { getEmployeeId } from '../../../../utility';

const { Header, Body, Footer, Dialog } = Modal;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltipmui {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'grey',
    color: 'white',
    fontSize: 11,
    width: "110px",
    height: "auto",
    padding: "5px",
    border: "1px solid #D3D3D3"
  },
}));

const overTime = [
  // Week 1 data
  [
    { day: 'Sep 1', Hours: 5 },
    { day: 'Sep 2', Hours: 2 },
    { day: 'Sep 3', Hours: 1 },
    { day: 'Sep 4', Hours: 3 },
    { day: 'Sep 5', Hours: 7 },
    { day: 'Sep 6', Hours: 4 },
    { day: 'Sep 7', Hours: 2 },
  ],
  // Week 2 data
  [
    { day: 'Sep 8', Hours: 6 },
    { day: 'Sep 9', Hours: 3 },
    { day: 'Sep 10', Hours: 5 },
    { day: 'Sep 11', Hours: 1 },
    { day: 'Sep 12', Hours: 8 },
    { day: 'Sep 13', Hours: 4 },
    { day: 'Sep 14', Hours: 7 },
  ],
  // Week 3 data
  [
    { day: 'Sep 15', Hours: 4 },
    { day: 'Sep 16', Hours: 2 },
    { day: 'Sep 17', Hours: 6 },
    { day: 'Sep 18', Hours: 3 },
    { day: 'Sep 19', Hours: 7 },
    { day: 'Sep 20', Hours: 5 },
    { day: 'Sep 21', Hours: 2 },
  ]
];

export default class OrgDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWeek: 0,
      currentMonth: 0,
      dashboard: {},
    };
  }

  componentDidMount() {
    this.getEmployeeDashList()

  }

  getEmployeeDashList = () => {
    const EmployeeId = getEmployeeId()
    if(EmployeeId > 0){
        getEmployeeDashboardDetail(new Date().toISOString().substring(0, 16)).then(res => {
            this.setState({ dashboard: res.data });
        });
    }else{
        this.setState({ dashboard: false });
    }
}

  handleNextWeek = () => {
    if (this.state.currentWeek < overTime.length - 1) {
      this.setState((prevState) => ({
        currentWeek: prevState.currentWeek + 1
      }));
    }
  };

  handlePrevWeek = () => {
    if (this.state.currentWeek > 0) {
      this.setState((prevState) => ({
        currentWeek: prevState.currentWeek - 1
      }));
    }
  };
  handleNextMonth = () => {
    if (this.state.currentMonth < overTime.length - 1) {
      this.setState((prevState) => ({
        currentMonth: prevState.currentMonth + 1
      }));
    }
  };

  handlePrevMonth = () => {
    if (this.state.currentMonth > 0) {
      this.setState((prevState) => ({
        currentMonth: prevState.currentMonth - 1
      }));
    }
  };

  hideAttendanceList = () => {
    this.setState({
      isOpenList: false
    })
  }

  render() {
    const { currentWeek, currentMonth, dashboard } = this.state;
    const data = [
      { name: 'Present', August: 65, September: 70 },
      { name: 'Absent', August: 45, September: 30 },
      { name: 'On Time', August: 60, September: 70 },
      { name: 'Late', August: 5, September: 8 },
      { name: 'Half Day', August: 5, September: 20 },
      { name: 'Leave', August: 5, September: 30 },
    ];
    const weekRanges = [
      "1 Sep - 7 Sep",
      "8 Sep - 14 Sep",
      "15 Sep - 21 Sep"
    ];
    const monthRanges = [
      "Aug - Sep",
      "Sep - Oct",
      "Oct - Nov"
    ];

    const onTimeCheckIn = [
      { date: "5 Sep", time: -5, employees: 100 },
      { date: "6 Sep", time: 15, employees: 200 },
      { date: "7 Sep", time: 10, employees: 300 },
      { date: "8 Sep", time: 5, employees: 150 },
      { date: "9 Sep", time: -3, employees: 250 },
      { date: "10 Sep", time: 7, employees: 400 },
      { date: "11 Sep", time: 2, employees: 180 },
    ];


    const ontime = [
      { day: 'Mon', ontime: 13, late: -5 },
      { day: 'Tue', ontime: 5, late: -5 },
      { day: 'Wed', ontime: 10, late: -10 },
      { day: 'Thu', ontime: 7, late: -7 },
      { day: 'Fri', ontime: 3, late: -0 },
      { day: 'Sat', ontime: 5, late: -5 },
      { day: 'Sun', ontime: 10, late: -10 },
    ];


    return (
      <div>

        <div className='d-flex'>
          <div className="TeamDashCardsBody d-flex">
            <div className="p-0 col">
              <div className='mygrid-div'>
                <FcCalendar size={35} />
                <p className='mb-1'>DATE</p>
                <h5 >10 Sep 2024</h5>
              </div>
            </div>
            <div className="p-0 col">
              <div className='mygrid-div'>
                <FaPeopleGroup color='#102746' size={35} />
                <p className='mb-1'>TEAM MEMBERS</p>
                <h5>120</h5>
              </div>
            </div>

            <div className="p-0 col">
              <div className='mygrid-div'>
                <IoIosCheckboxOutline color='#45C56D' size={35} />
                <p className='mb-1'>PRESENT</p>
                <h5 >60</h5>
              </div>
            </div>
            <div className="p-0 col">
              <div className='mygrid-div'>
                <MdFlightTakeoff color='#4DC2DD' size={35} />
                <p className='mb-1'>ON LEAVE</p>
                <h5 >15</h5>
              </div>
            </div>
            <div className="p-0 col">
              <div className='mygrid-div'>
                <IoWarningOutline color='#f88535' size={35} />
                <p className='mb-1'>LATE</p>
                <h5 >45</h5>
              </div>
            </div>
            <div className="p-0 col">
              <div className='mygrid-div'>
                <FaUmbrellaBeach color='#7c4f9d' size={35} />
                <p className='mb-1'>HOLIDAY</p>
                <h5 >45</h5>
              </div>
            </div>
            <div className="p-0 col">
              <div className='mygrid-div'>
                <TbFriendsOff color='#9d8f8f' size={35} />
                <p className='mb-1'>WEEK OFF</p>
                <h5 >15</h5>
              </div>
            </div>
            <div className="p-0 col">
              <div style={{ borderRight: 'none' }} className='mygrid-div'>
                <FcClock color='#f88535' size={35} />
                <p className='mb-1'>ONTIME</p>
                <h5 >45</h5>
              </div>
            </div>

          </div>


        </div>
        <div className='mt-4 d-flex'>
          <div className='teamDashBarChart'>
            <div style={{ placeContent: 'space-between', margin: '0px 45px 10px 45px' }} className='d-flex'>
              <div>
                <h4>Work Trends</h4>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className='mychart_next' style={{ fontSize: '22px', marginRight: '10px' }}>
                  <i className='fa fa-caret-left' aria-hidden='true'></i>
                </span>
                <span>Aug - Sep</span>
                <span className='mychart_next' style={{ fontSize: '22px', marginLeft: '10px' }}>
                  <i className='fa fa-caret-right' aria-hidden='true'></i>
                </span>
              </div>
            </div>
            <ResponsiveContainer width='100%' height={210}>
              <BarChart
                data={data}

                margin={{
                  top: 5, right: 5, left: 5, bottom: 5,
                }}
              >
                <CartesianGrid />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="August" fill="#7790a9" radius={[10, 10, 0, 0]} />
                <Bar dataKey="September" fill="#b0cae7" radius={[10, 10, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>

          </div>
          <div style={{ marginLeft: '9px' }} className=''>
            <div className="p-0 col">
              <div className='mt-0 teamDashRightCard'>
                <p style={{ background: '#f88535', color: 'white', fontSize: '12px', fontWeight: '500', marginBottom: '1px' }}>ABSENCE RATE</p>
                <div className='p-1'>
                  <h4 className='mb-0' >15%</h4>
                  <span>-3 from Previous Month</span>
                </div>
              </div>
            </div>
            <div className="p-0 col">

              <div className=' teamDashRightCard'>
                <p style={{ background: '#45C56D', color: 'white', fontSize: '12px', fontWeight: '500', marginBottom: '1px' }}>OVERTIME RATE</p>
                <div className='p-1'>
                  <h4 className='mb-0' >10%</h4>
                  <span>-3 from Previous Month</span>
                </div>
              </div>
            </div>
            <div className="p-0 col">
              <div className='teamDashRightCard'>
                <p style={{ background: '#4DC2DD', color: 'white', fontSize: '12px', fontWeight: '500', marginBottom: '1px' }}>LATECOMER RATE</p>
                <div className='p-1'>
                  <h4 className='mb-0' >6%</h4>
                  <span>-3 from Previous Month</span>
                </div>

              </div>
            </div>
          </div>
          <div style={{ marginLeft: '20px' }} className="row">
            <LightTooltip title="Click to view more" placement="top" >
              <div onClick={() => {
                this.setState({ isOpenList: true, AttendanceStatusTitle: 'Early Birds' });
              }} className="teamActionCardBody col"><div className='teamActionCard'>
                  <i style={{ position: 'absolute', right: '2px', top: '2px' }} className="pay-table-linkIcon fa fa-external-link" aria-hidden="true"></i>
                  <GiHummingbird color='#45C56D' size={35} />
                  <p className='mb-0'>Monthly Early Birds</p>
                  <h5 >20</h5>
                </div></div>
            </LightTooltip>
            <LightTooltip title="Click to view more" placement="top" >
              <div onClick={() => {
                this.setState({ isOpenList: true, AttendanceStatusTitle: 'Lates' });
              }} className="teamActionCardBody col"><div className='teamActionCard'>
                  <i style={{ position: 'absolute', right: '2px', top: '2px' }} className="pay-table-linkIcon fa fa-external-link" aria-hidden="true"></i>
                  <GiSandsOfTime color='#f88535' size={35} />
                  <p className='mb-0'>Monthly Latecomers</p>
                  <h5 >5</h5>
                </div></div>
            </LightTooltip>
            <div className="w-100"></div>
            <LightTooltip title="Click to view more" placement="top" >
              <div onClick={() => {
                this.setState({ isOpenList: true, AttendanceStatusTitle: 'Perfect Attendance' });
              }} className="teamActionCardBody col">
                <div className='teamActionCard'>
                  <i style={{ position: 'absolute', right: '2px', top: '2px' }} className="pay-table-linkIcon fa fa-external-link" aria-hidden="true"></i>
                  <FaAward color='#09579d' size={35} />
                  <p className='mb-0'>Perfect Attendance</p>
                  <h5 >60</h5>
                </div></div>
            </LightTooltip>
            <LightTooltip title="Click to view more" placement="top" >
              <div onClick={() => {
                this.setState({ isOpenList: true, AttendanceStatusTitle: 'Absences' });
              }} className="teamActionCardBody col"><div className='teamActionCard'>
                  <i style={{ position: 'absolute', right: '2px', top: '2px' }} className="pay-table-linkIcon fa fa-external-link" aria-hidden="true"></i>
                  <FcLeave color='#45C56D' size={35} />
                  <p className='mb-0'>Absences</p>
                  <h5 >10</h5>
                </div></div>
            </LightTooltip>
          </div>
        </div>

        <div className='OrgDashBarChart'>
          <div className={dashboard.overtimeEnable ? 'col-md-6' : 'col-md-12'} style={{ borderRight: 'solid 1px #d5d4d4',textAlign: 'center' }}>

            <div style={{ placeContent: 'space-between', margin: '0px 45px 10px 45px' }} className='d-flex'>
              <div>
                <h4>On Time</h4>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={this.handlePrevWeek} className='mychart_next' style={{ fontSize: '22px', marginRight: '10px' }}>
                  <i className='fa fa-caret-left' aria-hidden='true'></i>
                </span>
                <span>{weekRanges[currentWeek]}</span>
                <span onClick={this.handleNextWeek} className='mychart_next' style={{ fontSize: '22px', marginLeft: '10px' }}>
                  <i className='fa fa-caret-right' aria-hidden='true'></i>
                </span>
              </div>
            </div>
            <ResponsiveContainer height={210}>
              <BarChart
                data={ontime}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis domain={[-15, 15]} ticks={[-15, -10, -5, 0, 5, 10, 15]} />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="black" strokeWidth={2} />
                <Bar dataKey="ontime" fill="#82ca9d" />
                <Bar dataKey="late" fill="#82ca9d" />

              </BarChart>
            </ResponsiveContainer>
          </div>
         {dashboard?.overtimeEnable && <div className='col-md-6' style={{ textAlign: 'center' }}>
            <div style={{ placeContent: 'space-between', margin: '0px 45px 10px 45px' }} className='d-flex'>
              <div>
                <h4>Overtime</h4>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={this.handlePrevWeek} className='mychart_next' style={{ fontSize: '22px', marginRight: '10px' }}>
                  <i className='fa fa-caret-left' aria-hidden='true'></i>
                </span>
                <span>{weekRanges[currentWeek]}</span>
                <span onClick={this.handleNextWeek} className='mychart_next' style={{ fontSize: '22px', marginLeft: '10px' }}>
                  <i className='fa fa-caret-right' aria-hidden='true'></i>
                </span>
              </div>
            </div>
            <ResponsiveContainer height={210}>
              <BarChart
                title='Sample'
                data={overTime[currentWeek]}
                margin={{
                  top: 5, right: 5, left: 5, bottom: 5,
                }}
              >
                <CartesianGrid />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="Hours" fill="#777ca3" radius={[10, 10, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>}
        </div>
        <Modal enforceFocus={false} size={"lg"} show={this.state.isOpenList} onHide={this.hideAttendanceList} >


          <Header closeButton>
            <h5 className="modal-title">{this.state.AttendanceStatusTitle}</h5>
          </Header>
          <Body>
            <AttendanceStatusList ></AttendanceStatusList>
          </Body>


        </Modal>
      </div>
    )
  }
}
